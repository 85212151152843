.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99999999;
  overflow: visible;
  background: url(../public/assets/images/preloader.gif) no-repeat center center;
  background-size: 30%;
  background-color: #f8f8f7;
}

@media (max-width: 1199px) {
  .preloader {
    background-size: 30%;
  }
}

@media (max-width: 991px) {
  .preloader {
    background-size: 40%;
  }
}

@media (max-width: 480px) {
  .preloader {
    background-size: 65%;
  }
}
